import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "loading" ]
  static targets = [ "wrapper", "select", "submitButton" ]

  navigate() {
    this.wrapperTarget.classList.add(this.loadingClass)
    this.submitButtonTarget.click()
    this.selectTarget.disabled = true
  }
}